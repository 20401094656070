import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import { MakeGoodFlashcardsPost } from "../blogPosts/makeGoodFlashcardsPost";
import { makingGoodFlashcards } from "../utils/seoText";

export const MakingGoodFlashCardsPage = () => {
  return (
    <Layout>
      <SEO {...makingGoodFlashcards} />
      <Navigation />
      {/* TODO (William): Probs good idea to refactor this on the next blog post. */}
      <MakeGoodFlashcardsPost />
      <Footer />
    </Layout>
  );
};

export default MakingGoodFlashCardsPage;
