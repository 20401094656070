import React from "react";
import DeadSeaCharacteristicsImg from "../images/blog/making-good-flashcards/deadSeaCharacteristicsComplexFlashcard.png";
import MaslowHierarchyFiveStepsImg from "../images/blog/making-good-flashcards/maslowHierarchyFiveStepsFlashcard.png";
import MaslowHierarchyFiveStepsGoodImg from "../images/blog/making-good-flashcards/maslowHierarchyFiveStepsWithImageFlashcard.png";
import PersonalizedCardImg from "../images/blog/making-good-flashcards/fiveEyesPersonalizedMnemonics.png";
import PersonalizedExampleCardImg from "../images/blog/making-good-flashcards/appealToAuthorityExampleFlashcard.png";
import FiveEyesComplexCard from "../images/blog/making-good-flashcards/fiveEyesHistoryComplexFlashcard.png";
import ContextFlashcard from "../images/blog/making-good-flashcards/xmlLabelWithLectureSlideContext.png";
import SukhansImg from "../images/blog/making-good-flashcards/sukhans.jpg";

export const MakeGoodFlashcardsPost: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl mx-auto">
          <article>
            <header>
              <div className="mb-6 text-center">
                <span className="text-base md:text-lg">
                  <span className="mr-2 text-purple-500 cursor-default">
                    GUIDE
                  </span>
                  <span className="text-blueGray-800">20 Jan, 2023</span>
                </span>
                <h2 className="mt-4 text-4xl font-bold md:text-5xl font-heading">
                  13 Rules for Making Useful Flashcards
                </h2>
              </div>
              <div className="flex justify-center mb-8">
                <img
                  className="object-cover w-12 h-12 rounded-full"
                  src={SukhansImg}
                  alt="sukhans"
                />
                <div className="pl-4">
                  <p className="mb-1 text-blueGray-500">Sukhans Asrani</p>
                  <p className="text-xs font-semibold text-purple-600">
                    Founder @ Zorbi
                  </p>
                </div>
              </div>
            </header>
            <div className="page-body">
              <p className="mb-6 leading-loose text-blueGray-800">
                Making useful flashcards is a skill that seems daunting at
                first, but the good news is that anyone can develop it. This
                guide gives you a list of thirteen simple rules that will help
                you make great flashcards.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Absorbing these rules will completely change the way you
                approach learning. If this is your first time learning how to
                make great flashcards, you&#x27;ll be amazed at how fast you can
                absorb and retain information.
              </p>

              <p className="mb-2 leading-loose text-blueGray-800">
                This guide provides:
              </p>
              <ul className="ml-6 leading-loose list-disc text-blueGray-800">
                <li className="leading-loose">
                  A simple test for figuring out if your flashcards are bad
                </li>
                <li className="ml-8">
                  Memorize this and you&#x27;ll know when to re-read this guide.
                </li>
                <li className="leading-loose">
                  Thirteen rules you should follow when making flashcards
                </li>
                <li className="ml-8">
                  If you&#x27;re new to flashcards, then pay attention to 
                  <a href="#rule-10">
                    <strong>Rule 10 (Don&#x27;t forget to cloze)</strong>
                  </a>
                  ,{" "}
                  <a href="#rule-4">
                    <strong>Rule 4 (Avoid complex cards) </strong>
                  </a>
                  and{" "}
                  <a href="#rule-7">
                    <strong>Rule 7 (Avoid lists)</strong>
                  </a>
                </li>
              </ul>
              <h2
                id="test-for-bad-flashcards"
                className="mt-4 mb-6 text-xl font-bold md:text-3xl font-heading"
              >
                Is it painful?: A test for bad flashcards
              </h2>
              <p className="mb-2 leading-loose text-blueGray-800">
                Answer the following questions honestly:
              </p>
              <ul className="mb-6 ml-4 list-disc text-blueGray-800">
                <li className="leading-loose text-blueGray-800">
                  Do you find yourself constantly forgetting flashcards?
                </li>
                <li className="leading-loose text-blueGray-800">
                  Does it feel like studying 5 cards is painful?
                </li>
              </ul>

              <p className="mb-6 leading-loose text-blueGray-800">
                If you answered yes, then there is a good chance that your
                flashcards are bad.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Read the following 13 rules and redesign your flashcards. Your
                learning speed will accelerate beyond what you thought was
                possible.
              </p>

              <h2 className="mt-4 mb-6 text-xl font-bold md:text-3xl font-heading">
                The Thirteen Rules
              </h2>
              <figure className="flex p-4 mb-6 whitespace-pre-wrap bg-purple-100 rounded-sm">
                <div className="mr-2 text-xl">
                  <span className="inline-block mr-2 font-sans no-underline align-text-bottom">
                    🛑
                  </span>
                </div>
                <div className="w-full leading-relaxed">
                  <strong>Before you get started</strong>: I know that these
                  rules might make it seem like you&#x27;ll be creating more
                  flashcards than you usually do. But if you&#x27;re using
                  spaced-repetition, then you&#x27;ll spend less time learning
                  overall by reducing the number of times spent learning bad
                  (and usually complex) cards.
                </div>
              </figure>

              <h3
                id="rule-1"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                1. Don&#x27;t learn things you don&#x27;t understand
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Learning facts from a medical textbook when you&#x27;ve never
                studied biology is a good way to make yourself miserable.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Although you might be able to rote-learn these facts and pass an
                exam, it&#x27;ll take far more time than it would have taken if
                you had existing foundational knowledge on the topic. It&#x27;s
                better to gain an understanding of the basics before using
                flashcards.
              </p>
              <h3
                id="rule-2"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                2. Get an overview first
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Learning from a flashcard deck without an overview of the topic
                will mean you can&#x27;t connect ideas together.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                You should start by watching/reading an overview of the topic
                which will connect ideas together and then proceed with testing
                yourself using individual questions and answers.
              </p>

              <figure className="flex p-4 mb-6 whitespace-pre-wrap bg-purple-100 rounded-sm">
                <div className="mr-2 text-xl">
                  <span className="inline-block mr-2 font-sans no-underline align-text-bottom">
                    💡
                  </span>
                </div>
                <div className="w-full leading-relaxed">
                  <strong>Pro Tip:</strong> You can use the Zorbi browser
                  extension to create flashcards while reading notes or while
                  watching lectures.
                </div>
              </figure>

              <h3
                id="rule-3"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                3. Don&#x27;t ignore the basics
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Sometimes people think they shouldn&#x27;t create flashcards for
                basic concepts, but the cost of forgetting the basics is much
                larger than the cost of occasionally reviewing them.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                You should still create flashcards for basic concepts.
                Don&#x27;t worry about wasting time, Zorbi&#x27;s
                spaced-repetition algorithm will schedule easy cards months away
                after you've answered them a few times.
              </p>
              <h3
                id="rule-4"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                4. Keep It Simple Stupid (KISS)
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Simple, small, and direct questions are easier to answer than
                complex questions.
              </p>
              <p className="mb-6 leading-loose text-blueGray-800">
                A lot of people that are new to spaced-repetition will create
                flashcards that contain too many points because they want to
                reduce their time doing reviews. Creating many small questions
                will actually cause you to &#x27;fail&#x27; cards less often and
                you&#x27;ll end up saving time.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                The following is an example of a complex card
              </p>
              <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                <img
                  className="mx-auto"
                  alt="a complex flashcard"
                  src={DeadSeaCharacteristicsImg}
                />
              </figure>

              <p className="mb-6 leading-loose text-blueGray-800">
                You could isolate each of those characteristics and break them
                into separate flashcards.
              </p>

              <figure className="flex p-4 mb-6 whitespace-pre-wrap bg-purple-100 rounded-sm">
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose text-blueGray-800">
                    <strong>Where is the Dead Sea located? </strong>:: on the
                    border between Israel and Jordan
                  </li>
                  <li className="leading-loose text-blueGray-800">
                    <strong>How long is the Dead Sea? </strong>::
                    <strong> </strong>
                    74km
                  </li>
                  <li className="leading-loose text-blueGray-800">
                    <strong>
                      Where does the &#x27;Dead&#x27; in Dead Sea come from?{" "}
                    </strong>
                    ::<strong> </strong>only simple organisms can live in it
                    because of the salt levels
                  </li>
                </ul>
              </figure>

              <p className="mb-6 leading-loose text-blueGray-800">
                Take note of how short and concise each of those questions and
                answers are.
              </p>
              <h3
                id="rule-5"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                5. Use pictures
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Our visual processing power is far superior to our verbal
                processing power - that&#x27;s why they say
                <em> a picture is worth a thousand words</em>. Using images,
                flowcharts, and diagrams can make concepts significantly easier
                to remember.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Most business students will have to learn and memorize
                Maslow&#x27;s Hierarchy of Needs:
              </p>
              <div className="flex flex-col items-center justify-between w-full lg:flex-row">
                <div className="w-1/2 px-4 py-0">
                  <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                    <img
                      alt="a bad flashcard"
                      src={MaslowHierarchyFiveStepsImg}
                    />
                    <figcaption className="mt-2 text-sm text-blueGray-700">
                      A bad flashcard
                    </figcaption>
                  </figure>
                </div>
                <div className="w-1/2 px-4 py-0">
                  <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                    <img
                      alt="a better flashcard"
                      src={MaslowHierarchyFiveStepsGoodImg}
                    />
                    <figcaption className="mt-2 text-sm text-blueGray-700">
                      A slightly better flashcard — the image helps you remember
                      it thanks to your visual cortex. The fact it&#x27;s a
                      pyramid acts as a model mnemonic.
                    </figcaption>
                  </figure>
                </div>
              </div>

              <p className="mb-6 leading-loose text-blueGray-800">
                The flashcard with the pyramid is going to be easier to
                remember. But in this case, both cards aren&#x27;t that great.
                You can improve on this card by adding a word or acronym
                mnemonic - see{" "}
                <a href="#rule-6">
                  <strong>rule 6</strong>
                </a>
                .
              </p>

              <h3
                id="rule-6"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                6. Use mnemonics
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                Mnemonics are techniques that make remembering things easy.
              </p>
              <p className="mb-6 leading-loose text-blueGray-800">
                You might have to force yourself to use mnemonics when you first
                get started, but over time you&#x27;ll find yourself doing it
                automatically.
              </p>
              <p className="mb-2 leading-loose text-blueGray-800">
                Some examples of mnemonics include:
              </p>

              <ul className="mb-4">
                <li className="leading-loose">
                  <strong>Acronym Mnemonics</strong>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Combine the first letter of each word into a new word or
                      acronym.
                    </li>
                  </ul>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Example: ROY G BIV — The colours of the rainbow (
                      <strong>r</strong>ed, <strong>o</strong>range,{" "}
                      <strong>y</strong>ellow, <strong>g</strong>reen,{" "}
                      <strong>b</strong>lue, <strong>i</strong>ndigo,{" "}
                      <strong>v</strong>iolet)
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="mb-4">
                <li className="leading-loose">
                  <strong>Sentence Mnemonics</strong>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Where the first letter of each word is combined to form a
                      phrase or sentence
                    </li>
                  </ul>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      &quot;<strong>R</strong>ichard <strong>o</strong>f{" "}
                      <strong>Y</strong>ork <strong>g</strong>ave{" "}
                      <strong>b</strong>attle <strong>i</strong>n{" "}
                      <strong>v</strong>
                      ain&quot; for the colours of the rainbow
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="">
                <li className="leading-loose">
                  <strong>Model Mnemonics</strong>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Using a mindmap, diagram, or graph to help you memorize an
                      idea
                    </li>
                  </ul>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Example:{" "}
                      <a
                        className="underline"
                        href="https://en.wikipedia.org/wiki/Maslow%27s_hierarchy_of_needs"
                      >
                        Maslow&#x27;s hierarchy of needs
                      </a>{" "}
                      from the previous question is always represented as a
                      pyramid
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="mb-6">
                <li className="leading-loose">
                  <strong>Visualization Techniques</strong>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Creating memory associations using images or
                      visualisations
                    </li>
                  </ul>
                  <ul className="ml-4 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      For example you{" "}
                      <a
                        className="underline"
                        href="https://artofmemory.com/wiki/How_to_Build_a_Memory_Palace"
                      >
                        &#x27;walk through&#x27; a memory palace
                      </a>{" "}
                      in your head to remember information.
                    </li>
                  </ul>
                </li>
              </ul>
              <h3
                id="rule-7"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                7. Avoid lists
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                The cost of remembering a list is brutal and you should avoid
                them in your flashcards. This rule is especially applicable to
                any unordered lists.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Unfortunately, lots of areas in academia force you to remember
                lists of information. If you find yourself in this situation
                then turn it into an ordered list if you can (e.g. alphabetical
                order) or use a sentence/acronym mnemonic.
              </p>
              <h3
                id="rule-8"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                8. Personalize
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                One of the best ways to make flashcards more memorable is to
                link them with something you already remember. You can do this
                by adding an example from your life or making mnemonics more
                personal.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                When forced to memorise lists, I&#x27;ll use the names of my
                friends in mnemonics to make them easy to remember.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                For example, I might have something like the following card:
              </p>

              <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                <img
                  className="mx-auto"
                  alt="a personalized flashcard"
                  src={PersonalizedCardImg}
                />
              </figure>
              <p className="mb-6 leading-loose text-blueGray-800">
                <em>
                  Note: The two U&#x27;s in the list make this card risky and
                  hard to remember, which is why lists can be bad. If this was
                  an important card to remember, I would also use{" "}
                  <a href="#rule-10">
                    <strong>cloze deletions (rule 10)</strong>
                  </a>{" "}
                  or{" "}
                  <a href="#rule-5">
                    <strong>images (rule 5)</strong>
                  </a>{" "}
                  to guarantee that I remember it.
                </em>
              </p>

              <h3
                id="rule-9"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                9. Use Examples
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                This rule is closely related with the previous one. Adding
                examples to cards can greatly reduce your learning time,
                especially if they are personalized.
              </p>

              <h3
                id="rule-10"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                10. Don&#x27;t forget to cloze
              </h3>

              <p className="mb-6 leading-loose text-blueGray-800">
                A <em>cloze deletion, </em>or <em>a cloze, </em>is a sentence
                where a part has been removed and replaced by three dots.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                I&#x27;ve found that creating clozes is the fastest and easiest
                way to convert my notes into learnable flashcards. It&#x27;s
                extremely easy to copy a sentence from a book and then remove a
                part of the text.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Rookies will often make <em>expensive</em> flashcards like the
                following:
              </p>
              <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                <img
                  className="mx-auto"
                  alt="another complex flashcard"
                  src={FiveEyesComplexCard}
                />
              </figure>

              <p className="mb-6 leading-loose text-blueGray-800">
                A quick and easy way to turn that card into a good set of
                flashcards would be:
              </p>

              <figure className="flex flex-col p-4 mb-6 whitespace-pre-wrap bg-purple-100 rounded-sm">
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose">
                    The ... is an intelligence alliance comprising Australia,
                    Canada, New Zealand, the United Kingdom, and the United
                    States.
                    <ul className="ml-8 list-disc text-blueGray-800">
                      <li className="leading-loose">Five Eyes</li>
                    </ul>
                  </li>
                </ul>
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose">
                    The Five Eyes countries are parties to the multilateral ...,
                    a treaty for joint cooperation in signals intelligence.
                    <ul className="ml-8 list-disc">
                      <li className="leading-loose">UKUSA Agreement</li>
                    </ul>
                  </li>
                </ul>
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose">
                    The UKUSA Agreement is a treaty for ...
                    <ul className="ml-8 list-disc text-blueGray-800">
                      <li className="leading-loose">
                        joint cooperation in signals intelligence
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose">
                    The origins of the Five Eyes can be traced back to the ...
                    that was issued by the Allies to lay out their goals for a
                    post-WW2 world.
                    <ul className="ml-8 list-disc text-blueGray-800">
                      <li className="leading-loose">Atlantic Charter</li>
                    </ul>
                  </li>
                </ul>
                <ul className="ml-4 list-disc text-blueGray-800">
                  <li className="leading-loose">
                    The ... can be traced back to the Atlantic Charter that was
                    issued by the Allies to lay out their goals for a post-WW2
                    world.
                    <ul className="ml-8 list-disc text-blueGray-800">
                      <li className="leading-loose">
                        origins of the Five Eyes
                      </li>
                    </ul>
                  </li>
                </ul>
              </figure>

              <p className="mb-6 leading-loose text-blueGray-800">
                Although that breaks a single flashcard into five cards,
                you&#x27;ll end up learning the content significantly faster and
                you&#x27;ll be less likely to forget a card. With
                spaced-repetition, the costs and probability of forgetting a
                bulky card is much higher than smaller and simpler cards. (See{" "}
                <a href="#rule-4">
                  <strong>Rule #4 - Keep it Simple Stupid</strong>
                </a>
                ). You might have also noticed that the last two cards test the
                same idea, this is known as{" "}
                <a href="#rule-11">
                  <strong>using redundancy (rule 11)</strong>
                </a>
                , discussed right below.
              </p>

              <figure className="flex p-4 mb-6 whitespace-pre-wrap bg-purple-100 rounded-sm">
                <div className="mr-2 text-xl">
                  <span className="inline-block mr-2 font-sans no-underline align-text-bottom">
                    💡
                  </span>
                </div>
                <div className="w-full leading-relaxed">
                  <strong>Pro Tip:</strong> You can make a cloze deletion with
                  Zorbi&#x27;s Notion integration by surrounding a part of text
                  with curly brackets. e.g. {"{{ Asia }} "}
                  is the world&#x27;s biggest continent.
                </div>
              </figure>

              <h3
                id="rule-11"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                11. Use redundancy
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                The idea of &quot;redundant information&quot; relates to
                creating cards that look like they&#x27;re duplicating
                information or adding more information than needed.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Some people think this contradicts{" "}
                <a href="#rule-4">
                  <strong>Rule #4 (Keep Things Simple)</strong>
                </a>{" "}
                but it can help with learning. Here are two few examples where
                redundant information can help:
              </p>

              <ul className="ml-4 list-disc text-blueGray-800">
                <li className="leading-loose">
                  <strong>Attack with different angles:</strong>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      This one is most common when studying languages. If
                      you&#x27;re learning Spanish, then you might be able to
                      remember that <em>phone</em> is <em>teléfono </em>but you
                      may not remember it the other way around (that{" "}
                      <em>teléfono</em> is <em>phone</em>). So make cards for
                      both translations!
                    </li>
                  </ul>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      For other complex knowledge items, you also want to
                      represent it in multiple ways. Memorizing different
                      representations of a fact is recommended when the fact is
                      important (or you keep forgetting it even while following
                      the other rules in this guide).
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="ml-4 list-disc text-blueGray-800">
                <li className="leading-loose">
                  <strong>Derivation Steps:</strong>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      If you&#x27;re learning something complex like a
                      mathematical proof then you should also memorize the steps
                      taken to derive that proof.
                    </li>
                  </ul>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Note: This isn&#x27;t rote-learning. There is science
                      backing the use of spaced-repetition for complex maths.
                      It&#x27;s the fastest way to teach your brain what the
                      correct path is when solving a problem.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="mb-6 ml-4 list-disc text-blueGray-800">
                <li className="leading-loose">
                  <strong>Handle Equivalence:</strong>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      Don&#x27;t punish yourself for getting a flashcard wrong
                      just because you gave the wrong &quot;form&quot; of the
                      answer.
                    </li>
                  </ul>
                  <ul className="ml-8 list-disc text-blueGray-800">
                    <li className="leading-loose">
                      When learning languages, this can often apply to synonyms
                      (unless the context of the card makes that synonym wrong).
                    </li>
                  </ul>
                </li>
              </ul>

              <h3
                id="rule-12"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                12. Add context cues
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                You can stick to{" "}
                <a href="#rule-4">
                  <strong>Rule #4 - Keep it Simple Stupid</strong>
                </a>{" "}
                while adding extra info that explains the answer.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                When learning university subjects, I liked screenshotting the
                lecture slide to add extra context to the card. Sometimes I
                would also merge this with{" "}
                <a href="#rule-8">
                  <strong>Rule #8 — Personalize</strong>
                </a>{" "}
                and reference some knowledge from another subject/topic that had
                an overlapping element.
              </p>

              <p className="mb-6 leading-loose text-blueGray-800">
                Here is a real flashcard from a university paper where we were
                learning about a programming language called XML:
              </p>

              <figure className="p-0 mx-0 my-6 text-center border-none rounded-none">
                <img
                  className="mx-auto"
                  alt="a flashcard with context"
                  src={ContextFlashcard}
                />
              </figure>

              <p className="mb-6 leading-loose text-blueGray-800">
                Although you might not know anything about XML, it should be
                clear how this is a good flashcard. It has a simple question, a
                simple answer, and a screenshot of the relevant part of the
                lecture slide. The screenshot provides enough context for me to
                refresh my overall knowledge on this fact.
              </p>

              <h3
                id="rule-13"
                className="mt-4 mb-6 text-lg font-bold md:text-xl font-heading"
              >
                13. Add Sources
              </h3>
              <p className="mb-6 leading-loose text-blueGray-800">
                For complex flashcards, it can also be useful to add the source
                of the information.
              </p>
              <p className="mb-6 leading-loose text-blueGray-800">
                You won&#x27;t need to do this often since screenshots of the
                area are usually enough, but you can add specific page numbers
                of textbooks or links to websites to your cards.
              </p>

              <hr className="mb-6 border-black" />

              <p className="mb-6 leading-loose text-blueGray-800">
                And that&#x27;s all - thanks for reading. If you get anything
                out of this then I&#x27;d recommend memorizing that{" "}
                <a href="#test-for-bad-flashcards">
                  <strong>&quot;Is it painful?&quot; test</strong>
                </a>{" "}
                at the start of this guide. The test should act as a reliable
                signal for when you need to re-read this guide and update your
                flashcards.
              </p>

              <p className="mb-2 leading-loose text-blueGray-800">
                <strong>Sources:</strong>
              </p>
              <ul className="ml-4 list-disc text-blueGray-800">
                <li className="leading-loose">
                  <a
                    className="underline"
                    href="https://www.supermemo.com/en/archives1990-2015/articles/20rules"
                  >
                    Effective learning: twenty rules of formulating knowledge
                  </a>
                </li>
              </ul>
              <ul className="ml-4 list-disc text-blueGray-800">
                <li className="leading-loose">
                  <a
                    className="underline"
                    href="https://supermemo.guru/wiki/Incremental_reading"
                  >
                    Incremental Reading
                  </a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};
